import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    flagBox: {
      borderRadius: 100,
      border: `1px solid ${theme.palette.text.primary}`,
      width: 25,
      [theme.breakpoints.down('md')]: {
        width: 20,
      },
    },

    flag: {
      objectFit: 'cover',
    },
  });

export default styles;
