import React from 'react';
import { withStyles } from '@material-ui/core';
import { Box, Stack } from '@mui/material';
import styles from './ProducerHomePage.styles';
import PageContainer from '../../../Components/PageContainer/PageContainer';
import SocialIconButton from '../../../Components/SocialIconButton/SocialIconButton';
import VTypography from '../../../Venti-UI-Kit/VTypography/VTypography';
import { useProducerHomePage } from './ProducerHomePage.hook';
import EventCardsGrid from './EventCardsGrid/EventCardsGrid';
import PinnedEventCard from './PinnedEventCard/PinnedEventCard';
import FlagIcon from '../../../Components/FlagIcon/FlagIcon';

const ProducerHomePage = ({ classes, urlParams }) => {
  const { producer, events, hasMoreEvents, getHomeEvents, userAlias, theme } =
    useProducerHomePage(urlParams);

  return (
    <PageContainer withFooter withPaddingTop={false} isLoading={!producer}>
      <Box minHeight={{ xs: '8vh', md: '15vh' }}>
        {producer?.bannerImg && (
          <img className={classes.producerBanner} src={producer?.bannerImg} alt="producerBanner" />
        )}
      </Box>
      <Stack className={classes.producerDataBox}>
        {producer?.logoImg && (
          <img className={classes.producerLogo} src={producer?.logoImg} alt="producerLogo" />
        )}

        <Stack className={classes.producerInfoBox} gap={1}>
          <Stack>
            <Stack flexDirection="row" gap={1} alignItems="center">
              <VTypography variant="h2" align="inherit">
                {producer?.name}
              </VTypography>
              {producer?.country && <FlagIcon country={producer?.country} />}
            </Stack>
            {producer?.city && (
              <VTypography variant="button1" color={theme.palette.grey[700]} align="inherit">
                {producer.city}
              </VTypography>
            )}
          </Stack>

          {producer?.socials && !!Object.keys(producer?.socials).length && (
            <Stack flexDirection="row" gap={1}>
              {Object.entries(producer?.socials)?.map(([social, link]) => (
                <SocialIconButton key={social} social={social} link={link} />
              ))}
            </Stack>
          )}
        </Stack>
        {producer?.pinnedEvent && (
          <PinnedEventCard event={producer?.pinnedEvent} userAlias={userAlias} />
        )}
        <EventCardsGrid
          events={events}
          userAlias={userAlias}
          hasMoreEvents={hasMoreEvents}
          getMoreEvents={getHomeEvents}
        />
        {producer?.description && (
          <Stack gap={2}>
            <VTypography variant="h3">Más sobre nosotros</VTypography>
            <VTypography variant="button1">{producer?.description}</VTypography>
          </Stack>
        )}
      </Stack>
    </PageContainer>
  );
};

export default withStyles(styles)(ProducerHomePage);
