import { createStyles } from '@material-ui/core';

const styles = (theme) =>
  createStyles({
    footerUrl: {
      color: `${theme.palette.text.primary} !important`,
      textDecoration: 'unset !important',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      gap: 4,

      '&:hover': {
        color: `${theme.palette.primary.main} !important`,
      },
    },
    sidebarContainer: {
      '& .MuiStack-root': {
        overflow: 'hidden',
      },
    },
    toggleSidebarButton: {
      height: 48,
      '&.MuiButtonBase-root': {
        backgroundColor: 'transparent',
        padding: 0,
        minWidth: 0,

        '& .MuiIcon-root': {
          color: theme.palette.text.primary,
        },

        '&:hover': {
          backgroundColor: 'transparent',
        },
      },
    },
  });

export default styles;
