import { useTheme } from '@material-ui/core';
import { useEffect } from 'react';
import { useHome } from '../../../hooks/useHome';

export const useProducerHomePage = (urlParams) => {
  const { userAlias } = urlParams;
  const theme = useTheme();

  const { events, getHomeEvents, hasMoreEvents, producer } = useHome(urlParams, 6);

  const formatProducerSocials = () => {
    if (!producer.socials) return;
    const socials = {};
    Object.entries(producer.socials).forEach(([key, value]) => {
      switch (key) {
        case 'facebook':
          socials.facebook = `https://facebook.com/${value}`;
          break;
        case 'instagram':
          socials.instagram = `https://instagram.com/${value}`;
          break;
        case 'x':
          socials.x = `https://x.com/${value}`;
          break;
        case 'tiktok':
          socials.tiktok = `https://tiktok.com/${value}`;
          break;
        case 'youtube':
          socials.youtube = `https://youtube.com/${value}`;
          break;
        default:
          socials[key] = `https://${value}`;
          break;
      }
    });
    socials.link = socials.web;
    delete socials.web;
    producer.socials = socials;
  };

  useEffect(() => {
    if (!producer) return;
    formatProducerSocials();
  }, [producer]);

  return {
    producer,
    events,
    hasMoreEvents,
    getHomeEvents,
    userAlias,
    theme,
  };
};
