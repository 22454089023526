import { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core';
import { handleRequestHelper } from '../../../utils/helpers';
import api from '../../../api/api';
import { formatDatetime, formatPrice } from '../../../utils/utils';
import { CURRENCY } from '../../../constants/types';

export const useVolumeLast30DaysTrend = (producerId) => {
  const [trend, setTrend] = useState();
  const [metrics, setMetrics] = useState([]);
  const theme = useTheme();

  const getMetrics = async () => {
    await handleRequestHelper({
      endpoint: () => api.getLast60DaysVolume(producerId),
      onSuccess: ({ last60DaysVolume }) => setMetrics(last60DaysVolume),
    });
  };

  const calculateTrend = () => {
    if (!metrics.length) return;

    const [last30DaysVolume, last60DaysVolume] = metrics;
    const { day, dayOfWeek, month, year } = formatDatetime(last30DaysVolume.start_date);
    const sign = last30DaysVolume.total_amount > last60DaysVolume.total_amount ? 1 : -1;
    const color = sign > 0 ? theme.palette.success.main : theme.palette.error.main;
    const percentage = (last30DaysVolume.total_amount * 100) / last60DaysVolume.total_amount;

    const last30DaysVolumeFormatted = formatPrice(
      last30DaysVolume.total_amount,
      false,
      true,
      CURRENCY.ARS
    );

    const last60DaysVolumeFormatted = formatPrice(
      last60DaysVolume.total_amount,
      false,
      true,
      CURRENCY.ARS
    );

    setTrend({
      color,
      percentage: sign * percentage.toFixed(2),
      last30DaysDate: `${dayOfWeek}, ${day} de ${month} de ${year}`,
      last30DaysVolume: last30DaysVolumeFormatted,
      last60DaysVolume: last60DaysVolumeFormatted,
    });
  };

  useEffect(() => {
    getMetrics();
  }, []);

  useEffect(() => {
    calculateTrend();
  }, [metrics]);

  return {
    trend,
    theme,
  };
};
