import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import {
  ENVIRONMENTS,
  SORT_DIRECTION,
  CURRENCY,
  NOTIFICATION_VARIANT,
  USER_ROLES,
  USER_PERMISSIONS,
} from '../constants/types';
import {
  WHITE_LABELS,
  PRODUCTION_URLS_BY_WHITE_LABEL,
  STAGING_URLS_BY_WHITE_LABEL,
  PRODUCTION_URLS,
  STAGING_URLS,
} from '../constants/whiteLabels';
import permissions from '../Providers/Permissions/Permissions';
import { REGULAR_EXPRESSION } from '../constants/constants';
import priceProvider from '../Providers/Price/Price';

const DATE_FORMAT = 'es-ar';

export const formatPrice = (
  priceToFormat,
  decimals = true,
  showCurrency = false,
  currency = CURRENCY.ARS
) => {
  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  });
  const currencyLabel = showCurrency ? currency : '';
  return `${formatter.format(priceToFormat)} ${currencyLabel}`;
};

export const formatPriceWithCurrency = (priceToFormat, currency) => {
  const decimals = priceProvider.getDecimalsByCurrency(currency);
  const formatter = new Intl.NumberFormat('es-AR', {
    style: 'currency',
    currency,
    minimumFractionDigits: decimals ? 2 : 0,
    maximumFractionDigits: decimals ? 2 : 0,
  });
  return `${formatter.format(priceToFormat)}`;
};

export const moveItemToFirstPlaceInList = (item, list) => {
  return list.sort((x, y) => (item === x ? -1 : item === y));
};

export const formatDate = (date) => {
  const localeDate = new Date(date).toLocaleString(DATE_FORMAT, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
  return localeDate.split(',')[0];
};

export const getThemeByBaseUrl = () => {
  const themeByUrl = {
    mandarine: WHITE_LABELS.MANDARINE,
    underclub: WHITE_LABELS.UNDER_CLUB,
    buenasnochespro: WHITE_LABELS.BNP,
    bnphernancattaneo: WHITE_LABELS.HERNAN_CATTANEO,
  };

  const currentThemeKey = Object.keys(themeByUrl).find((themeUrl) =>
    window.location.origin.includes(themeUrl)
  );

  return themeByUrl[currentThemeKey] ?? WHITE_LABELS.VENTI;
};

export const getEnviroment = () => {
  let currentUrl = window.location.host;

  currentUrl = currentUrl.startsWith('www.') ? currentUrl.slice(4) : currentUrl;

  if (PRODUCTION_URLS.some((url) => url === currentUrl)) return ENVIRONMENTS.PRODUCTION;

  if (STAGING_URLS.some((url) => url === currentUrl)) return ENVIRONMENTS.STAGING;

  return ENVIRONMENTS.LOCAL;
};

export const getBaseUrlByThemeProduction = (theme) => {
  return (
    PRODUCTION_URLS_BY_WHITE_LABEL[theme] || PRODUCTION_URLS_BY_WHITE_LABEL[WHITE_LABELS.VENTI]
  );
};

export const getBaseUrlByThemeStaging = (theme) => {
  return STAGING_URLS_BY_WHITE_LABEL[theme] || STAGING_URLS_BY_WHITE_LABEL[WHITE_LABELS.VENTI];
};

export const getBaseUrlByTheme = (theme) => {
  const env = getEnviroment();

  let baseUrl = '';

  if (env === ENVIRONMENTS.PRODUCTION) {
    baseUrl = getBaseUrlByThemeProduction(theme);
  } else if (env === ENVIRONMENTS.STAGING) {
    baseUrl = getBaseUrlByThemeStaging(theme);
  }
  return baseUrl;
};

export const getUserHomeLink = (producer, user) => {
  const { id, theme } = producer;
  const { alias } = user;
  const baseUrl = getBaseUrlByTheme(theme);

  return `${baseUrl}/productora/eventos/${id}/${alias}`;
};

export const getEventLink = (event, user) => {
  const {
    urlName,
    Producer: { theme },
  } = event;

  const { role, alias } = user;
  const baseUrl = getBaseUrlByTheme(theme);

  return permissions.isSeller(role)
    ? `${baseUrl}/evento/${urlName}/${alias}`
    : `${baseUrl}/evento/${urlName}`;
};

export const sortArrayOrderByProps = (array, field, asc = true) => {
  const compareFunction = (a, b) => {
    const getNestedValue = (obj, props) => {
      const [prop, ...rest] = props.split('.');
      const value = obj[prop];
      return rest.length ? getNestedValue(value, rest.join('.')) : value;
    };

    const valueA = getNestedValue(a, field);
    const valueB = getNestedValue(b, field);

    if (valueA > valueB) return asc ? 1 : -1;
    if (valueA < valueB) return asc ? -1 : 1;
    return 0;
  };

  array.sort(compareFunction);

  return array;
};

export const sortObjectArray = (array, field, order = SORT_DIRECTION.ASC) => {
  return array.sort((a, b) => {
    if (a[field] > b[field]) {
      return order === SORT_DIRECTION.ASC ? 1 : -1;
    }
    if (b[field] > a[field]) {
      return order === SORT_DIRECTION.ASC ? -1 : 1;
    }
    return 0;
  });
};

export const copyToClipboard = (text, showNotification) => {
  navigator.clipboard.writeText(text);
  showNotification('Enlace copiado', NOTIFICATION_VARIANT.SUCCESS);
};

export const getUserInicials = ({ firstName, lastName, mail }) => {
  let inicials = '';
  if (firstName) {
    inicials = firstName.charAt(0);
    if (lastName) inicials += lastName.charAt(0);
  } else {
    inicials = mail.charAt(0);
  }
  return inicials.toUpperCase();
};

export const userIsBlacklistedFromEvent = (event, user) => {
  const { usersBlacklisted } = event;
  return usersBlacklisted.some(({ alias }) => user.alias === alias);
};

export const formatDatetime = (datetime) => {
  const parsedDate = new Date(datetime);

  return {
    day: format(parsedDate, 'd', { locale: es }),
    dayOfWeek: format(parsedDate, 'EEEE', { locale: es }),
    month: format(parsedDate, 'LLLL', { locale: es }),
    year: format(parsedDate, 'yyyy', { locale: es }),
    date: format(parsedDate, 'dd/MM/yyyy', { locale: es }),
    time: format(parsedDate, 'HH:mm', { locale: es }),
    timeWithSeconds: format(parsedDate, 'HH:mm:ss', { locale: es }),
    fullTimeDate: format(parsedDate, 'dd/MM/yyyy HH:mm:ss', { locale: es }),
  };
};

export const getTwoDigitNumber = (number) => {
  return number < 10 ? `0${number}` : number;
};

export const countdownTimer = (durationInSeconds, display, onTimerFinished) => {
  let timer = durationInSeconds;
  let minutes;
  let seconds;

  const intervalCounter = setInterval(() => {
    if (timer > 0) {
      timer -= 1;
      minutes = parseInt(timer / 60, 10);
      seconds = parseInt(timer % 60, 10);
    } else {
      minutes = 0;
      seconds = 0;
      clearInterval(intervalCounter);
      onTimerFinished();
    }

    minutes = getTwoDigitNumber(minutes);
    seconds = getTwoDigitNumber(seconds);

    display.textContent = `${minutes}:${seconds}`;
  }, 1000);
};

export const isValidMail = (mailToValidate) => {
  if (!mailToValidate.match(REGULAR_EXPRESSION.MAIL_VALIDATION)) return false;

  const [mailName, mailDomain] = mailToValidate.split('@');
  const splittedDomain = mailDomain.split('.');
  const [domainOrganization, domainType] = [splittedDomain[0], splittedDomain.slice(1).join('.')];
  const invalidOrganizations = ['hormail', 'hotmai', 'gmial', 'gmai'];
  const invalidDomainTypes = ['con'];
  const invalidDomains = ['gmail.com.ar'];
  if (
    !mailName ||
    !domainOrganization ||
    !domainType ||
    invalidDomains.includes(mailDomain) ||
    invalidOrganizations.includes(domainOrganization) ||
    invalidDomainTypes.includes(domainType)
  )
    return false;
  return true;
};

export const removeStringSpaces = (string) => string.replace(/\s/g, '');

export const capitalizeFirstLetter = (str) => {
  if (!str) return '';
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const getUrlSearchParams = () => {
  const urlParams = new URLSearchParams(window.location.search);
  return Object.fromEntries(urlParams.entries());
};

export const validateUserRole = (userRole, rolePermission) => {
  if (!userRole) return false;
  if (userRole === USER_ROLES.SUPER_ADMIN) return true;

  switch (rolePermission) {
    case USER_PERMISSIONS.SUPER_ADMIN:
      return userRole === USER_ROLES.SUPER_ADMIN;
    case USER_PERMISSIONS.ADMIN:
      return userRole === USER_ROLES.ADMIN;
    case USER_PERMISSIONS.ADMIN_WITH_READ_ONLY:
      return userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.READ_ONLY_ADMIN;
    case USER_PERMISSIONS.SELLERS:
      return (
        userRole === USER_ROLES.ADMIN ||
        userRole === USER_ROLES.SELLER_WITH_CASH ||
        userRole === USER_ROLES.SELLER
      );
    case USER_PERMISSIONS.SELLER_WITH_CASH:
      return userRole === USER_ROLES.ADMIN || userRole === USER_ROLES.SELLER_WITH_CASH;
    case USER_PERMISSIONS.BACKOFFICE:
      return userRole !== USER_ROLES.CUSTOMER;
    case USER_PERMISSIONS.LOGGED:
      return !!userRole;
    default:
      return false;
  }
};

export const formatLargeNumber = (value) => {
  if (value >= 1000000000) {
    const formatted = (value / 1000000000).toFixed(1);
    return formatted.endsWith('.0') ? `${Math.round(value / 1000000000)}B` : `${formatted}B`;
  }
  if (value >= 1000000) {
    const formatted = (value / 1000000).toFixed(1);
    return formatted.endsWith('.0') ? `${Math.round(value / 1000000)}M` : `${formatted}M`;
  }
  if (value >= 1000) {
    const formatted = (value / 1000).toFixed(1);
    return formatted.endsWith('.0') ? `${Math.round(value / 1000)}K` : `${formatted}K`;
  }
  return value;
};

export default { formatPrice, formatDate };
