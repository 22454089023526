import React from 'react';
import { Button, withStyles } from '@material-ui/core';
import styles from './SocialIconButton.styles';
import VIcon from '../../Venti-UI-Kit/VIcon/VIcon';

const SocialIconButton = ({ classes, social, link }) => {
  return (
    <Button className={classes.button} onClick={() => window.open(link, '_blank')}>
      <VIcon name={social} size={16} />
    </Button>
  );
};

export default withStyles(styles)(SocialIconButton);
