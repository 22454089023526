import { Icon } from '@mui/material';
import React from 'react';
import { Facebook, Instagram, X, YouTube } from '@mui/icons-material';
import { MATERIAL_UI_ICONS } from '../../constants/types';

const VIcon = ({ name, color, size, baseClassName = MATERIAL_UI_ICONS.OUTLINED, ...props }) => {
  const styles = { color, fontSize: size };
  let Component;
  switch (name) {
    case 'instagram':
      Component = Instagram;
      break;
    case 'facebook':
      Component = Facebook;
      break;
    case 'x':
      Component = X;
      break;
    case 'youtube':
      Component = YouTube;
      break;
    default:
      Component = Icon;
  }

  return (
    <Component baseClassName={baseClassName} sx={styles} {...props}>
      {name}
    </Component>
  );
};

export default VIcon;
