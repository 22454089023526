import React from 'react';
import { Grid } from '@mui/material';
import { ActiveEventsVolumeBarChart } from '../../../Components/Charts/ActiveEventsVolumeBarChart/ActiveEventsVolumeBarChart';
import TopCustomersTable from '../../../Components/Tables/TopCustomersTable/TopCustomersTable';
import VolumeLast30DaysTrend from '../../../Components/Charts/VolumeLast30DaysTrend/VolumeLast30DaysTrend';
import AmountOrdersByLocationTable from '../../../Components/Tables/AmountOrdersByLocationTable/AmountOrdersByLocationTable';

const ProducerReports = ({ producerId }) => {
  return (
    <Grid container padding={0} spacing={2} columns={{ xs: 1, sm: 8, lg: 12 }}>
      <Grid item xs={1} sm={8} lg={12}>
        <VolumeLast30DaysTrend producerId={producerId} />
      </Grid>
      <Grid item xs={1} sm={8} lg={12}>
        <ActiveEventsVolumeBarChart producerId={producerId} />
      </Grid>
      <Grid item xs={1} sm={8} lg={6}>
        <TopCustomersTable producerId={producerId} />
      </Grid>
      <Grid item xs={1} sm={8} lg={6}>
        <AmountOrdersByLocationTable producerId={producerId} />
      </Grid>
    </Grid>
  );
};

export default ProducerReports;
